<template>
  <tr
    class="border-t border-black"
    :class="{
      'border-dashed':
        !useConfig().isDefaultReceiptTemplate ||
        (!useConfig().isPosWholesale && useConfig().isDefaultReceiptTemplate),
    }"
  >
    <!-- HOLD NOTE -->
    <template v-if="isHoldNote">
      <td colspan="2" class="py-[10px]">
        <div class="flex flex-col gap-[10px]">
          <p class="font-bold text-sm leading-base text-black">Hold</p>
          <p v-if="item?.note" class="text-black">
            {{ item.note }}
          </p>
        </div>
      </td>
    </template>

    <!-- REGULAR ITEM -->
    <template v-else>
      <td class="text-right align-top">
        <div
          :class="[
            useConfig().isBrandedWholesaleReceiptTemplate ? 'text-[13px]' : '',
          ]"
          class="py-[10px] font-bold"
        >
          {{ item.units }}
        </div>
      </td>
      <td class="pl-[15px] w-full">
        <div class="flex justify-between pr-[10px]">
          <div
            :class="{
              'gap-[5px]': !useConfig().isBrandedWholesaleReceiptTemplate,
            }"
            class="flex flex-col py-[10px]"
          >
            <span
              :class="[
                useConfig().isBrandedWholesaleReceiptTemplate
                  ? 'text-[13px]'
                  : '',
              ]"
              class="font-bold"
            >
              {{
                route.query?.display_secondary_name
                  ? getProductName(item.ext_title)
                  : getProductName(item.name)
              }}
            </span>

            <!-- composite items -->
            <div
              v-if="Object.values(item.set_items || {})?.length"
              :class="[
                useConfig().isBrandedWholesaleReceiptTemplate
                  ? 'text-[13px]'
                  : '',
              ]"
              class="flex flex-col"
            >
              <span v-for="setItem in Object.values(item.set_items)">
                {{ (setItem as any).product }}
              </span>
            </div>

            <!-- addons -->
            <div
              v-if="item.addons?.length"
              :class="[
                useConfig().isBrandedWholesaleReceiptTemplate
                  ? 'text-[13px]'
                  : '',
              ]"
              class="flex flex-col"
            >
              <span
                v-for="ad in item.addons
                  .map((addon) => addon.value)
                  .flat(Infinity)"
              >
                {{
                  (ad as AddonValue).name
                    ? useCapitalize((ad as AddonValue).name?.toString())
                    : useCapitalize(
                        (ad as AddonValue).slug?.toString().replace(/-/g, " ")
                      )
                }}
              </span>
            </div>

            <!-- LOTS INFO -->
            <span
              v-if="item.selected_lot || item.selected_supplier"
              class="text-[12px] leading-[20px]"
            >
              {{
                useConfig().isLotsSupplierGrouped
                  ? !useConfig().isHideSupplier &&
                    !useConfig().isBrandedWholesaleReceiptTemplate
                    ? item.selected_supplier?.short_name
                    : ""
                  : item.selected_lot?.number
              }}
            </span>
            <!-- regular lots -->
            <span
              v-else-if="
                !useConfig().isLotsSupplierGrouped &&
                !useConfig().isBrandedWholesaleReceiptTemplate &&
                item.lots?.length &&
                item.lots.some((l) => l.number || l.supplier_short)
              "
              class="text-[12px] leading-[20px]"
            >
              {{
                item?.lots
                  ?.map(
                    (l) =>
                      `${[
                        ...(l.number ? [l.number] : []),
                        ...(l.supplier_short ? [l.supplier_short] : []),
                      ].join(" ")}`
                  )
                  .join(", ")
              }}
            </span>
            <!-- supplier grouped lots -->
            <span
              v-else-if="
                useConfig().isLotsSupplierGrouped &&
                !useConfig().isHideSupplier &&
                !useConfig().isBrandedWholesaleReceiptTemplate &&
                item.lots?.length &&
                item.lots.some((i) => i.supplier_short)
              "
              class="text-[12px] leading-[20px]"
            >
              {{ item?.lots?.map((l) => l.supplier_short).join(", ") }}
            </span>

            <div
              v-if="item.picker_notes"
              class="flex flex-col text-[12px] leading-[20px]"
            >
              <span>
                {{ item.picker_notes }}
              </span>
            </div>
          </div>
        </div>
      </td>
      <td
        v-if="useConfig().isBrandedWholesaleReceiptTemplate"
        :class="{
          'text-[13px] max-w-[105px]':
            useConfig().isBrandedWholesaleReceiptTemplate,
        }"
        class="flex flex-col text-right py-[10px]"
      >
        <span
          v-if="item.selected_supplier"
          class="truncate overflow-hidden text-ellipsis"
        >
          {{ item.selected_supplier?.short_name }}
        </span>
        <!-- regular lots -->
        <span
          v-else-if="
            !useConfig().isLotsSupplierGrouped &&
            item.lots?.length &&
            item.lots.some((l) => l.supplier_short)
          "
          class="truncate overflow-hidden text-ellipsis"
        >
          {{
            item?.lots
              ?.map(
                (l) =>
                  `${[...(l.supplier_short ? [l.supplier_short] : [])].join(
                    " "
                  )}`
              )
              .join(", ")
          }}
        </span>
        <!-- supplier grouped lots -->
        <span
          v-else-if="
            useConfig().isLotsSupplierGrouped &&
            item.lots?.length &&
            item.lots.some((i) => i.supplier_short)
          "
          class="truncate overflow-hidden text-ellipsis"
        >
          {{ item?.lots?.map((l) => l.supplier_short).join(", ") }}
        </span>
      </td>
    </template>
  </tr>
</template>

<script lang="ts" setup>
import { AddonValue } from "~/types/reports";

const props = defineProps<{
  item: any;
}>();

const route = useRoute(useRoutesNames().printIdTypeReport);

const symbolsTitles = ["TM", "Registered", "PBR"];

function getProductName(itemName) {
  symbolsTitles.forEach((symbol) => (itemName = itemName.replace(symbol, "")));
  return itemName;
}

const isHoldNote = computed(() => props.item.order || props.item?.order === 0);
</script>
